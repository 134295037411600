import React, { useEffect } from 'react';
import { navigate, Link } from 'gatsby';

import Seo from '../../components/SEO';

import useSound from 'use-sound';
import Switch from '../../sounds/switch.mp3';
import Fb from '../../sounds/fb.mp3';
import Tw from '../../sounds/tweet.mp3';
import In from '../../sounds/insta.mp3';

import ThankYou from '../../svg/thankyou.svg';
import Confetti from 'react-confetti';

function SuccessPage({ location }) {
  const showSuccessScreen = location.state && location.state.orderId;
  const [pop] = useSound(Switch);
  const [facebook] = useSound(Fb, { volume: 0.6 });
  const [twitter] = useSound(Tw);
  const [instagram] = useSound(In, { volume: 0.75 });

  useEffect(() => {
    if (!showSuccessScreen) {
      const navigateTimer = setTimeout(() => {
        navigate(`/`);
      }, 3000);

      return () => clearTimeout(navigateTimer);
    }
  }, [showSuccessScreen]);

  if (!showSuccessScreen)
    return (
      <React.Fragment>
        <div className="mb-6">
          <h1 className="mb-3 text-3xl font-bold md:text-6xl text-primary">
            oh uh!
          </h1>
        </div>
        <p>you've no business being on this page!</p>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Seo pageTitle="Thank you for your order" />
      <Confetti recycle={0} />
      <div className="mb-3">
        <div className="flex flex-col items-center py-8 text-primary">
          <ThankYou className="w-5/6 lg:4/5" />
        </div>
        <h1 className="mb-3 text-4xl font-bold text-center md:text-5xl text-primary">
          thank you!
        </h1>
      </div>
      <p className="w-4/6 py-4 pb-16 m-auto text-base text-center md:w-3/6 text-primary opacity-80">
        we’ve emailed you all the details for safe keeping and your order will
        be on its way very soon.
        <br />
        <br />
        see you soon :)
        <br />
        <br />
      </p>
      <div className="flex flex-col justify-center pb-8 mx-16 mt-16 lg:mx-64">
        <Link className="secondary-btn" to="/" onClick={pop}>
          <b>take me home</b>
        </Link>
      </div>
      <div className="flex flex-col justify-between h-full items-base text-primary">
        <ul className="flex flex-row justify-center p-4 m-auto text-color">
          <Link
            href="http://instagram.com/deadnice.co"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 text-sm hover:text-primary"
            onClick={instagram}
          >
            <div className="flex flex-row py-3">
              <li className="my-1">
                <svg
                  className="w-5 h-5 mr-3 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.8207826,0 L7.13721739,0 C3.20173913,0 0,3.20173913 0,7.13721739 L0,16.8207826 C0,20.7562609 3.20173913,23.958 7.13721739,23.958 L16.8207826,23.958 C20.7562609,23.958 23.958,20.7562609 23.958,16.8207826 L23.958,7.13721739 C23.958,3.20173913 20.7562174,0 16.8207826,0 Z M21.5478261,16.8207826 C21.5478261,19.4314348 19.4314348,21.5478261 16.8207826,21.5478261 L7.13721739,21.5478261 C4.52656522,21.5478261 2.41017391,19.4314348 2.41017391,16.8207826 L2.41017391,7.13721739 C2.41017391,4.52652174 4.52656522,2.41017391 7.13721739,2.41017391 L16.8207826,2.41017391 C19.4314348,2.41017391 21.5478261,4.52652174 21.5478261,7.13721739 L21.5478261,16.8207826 L21.5478261,16.8207826 Z"></path>
                  <path
                    d="M11.979,5.7826087 C8.56230435,5.7826087 5.7826087,8.56230435 5.7826087,11.9789565 C5.7826087,15.3956087 8.56230435,18.1753478 11.979,18.1753478 C15.3956957,18.1753478 18.1753913,15.3956522 18.1753913,11.9789565 C18.1753913,8.56226087 15.3956957,5.7826087 11.979,5.7826087 Z M11.979,15.7652174 C9.88791304,15.7652174 8.19278261,14.070087 8.19278261,11.979 C8.19278261,9.88791304 9.88795652,8.19278261 11.979,8.19278261 C14.070087,8.19278261 15.7652174,9.88791304 15.7652174,11.979 C15.7652174,14.0700435 14.0700435,15.7652174 11.979,15.7652174 Z"
                    id="XMLID_81_"
                  ></path>
                  <circle
                    cx="18.1872174"
                    cy="5.82921739"
                    r="1.48473913"
                  ></circle>
                </svg>
              </li>
            </div>
          </Link>
          <Link
            href="https://twitter.com/deadniceco"
            target="_blank"
            className="px-4 text-sm hover:text-primary"
            rel="noopener noreferrer"
            onClick={twitter}
          >
            <div className="flex flex-row py-3">
              <li className="my-1">
                <svg
                  className="w-5 h-5 mr-3 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124-4.09-.193-7.715-2.157-10.141-5.126-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548z" />
                </svg>
              </li>
            </div>
          </Link>
          <Link
            href="https://fb.me/deadnice.co"
            target="_blank"
            className="px-4 text-sm hover:text-primary"
            rel="noopener noreferrer"
            onClick={facebook}
          >
            <div className="flex flex-row py-3">
              <li className="my-1">
                <svg
                  className="w-5 h-5 mr-3 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.0921844,0 L1.91022044,0 C0.853707415,0 0,0.798 0,1.785 L0,19.64475 C0,20.62875 0.853707415,21.429 1.91022044,21.429 L22.0921844,21.429 C23.1438878,21.429 24,20.6295 24,19.64475 L24,1.785 C23.9991984,0.798 23.1430862,0 22.0921844,0 Z M16.1322645,6.36225 C15.6360721,6.09075 15.242485,5.87625 14.7478958,5.87625 C14.2637275,5.87625 13.8501002,5.9895 13.638477,6.18 C13.4268537,6.369 13.3218437,6.735 13.3218437,7.27575 L13.3218437,8.076 L15.9102204,8.076 L15.3498998,10.302 L13.3218437,10.302 L13.3218437,17.6985 L10.0705411,17.6985 L10.0705411,10.302 L8.55310621,10.302 L8.55310621,8.076 L10.0721443,8.076 L10.0721443,7.22625 C10.0721443,6.3315 10.1867735,5.796 10.4128257,5.35425 C10.6380762,4.914 11.0749499,4.4235 11.6825651,4.146 C12.2893788,3.86925 13.1871743,3.72975 14.1162325,3.72975 C15.0685371,3.72975 15.8124248,4.02675 16.7246493,4.266 L16.1322645,6.36225 Z"></path>
                </svg>
              </li>
            </div>
          </Link>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default SuccessPage;
